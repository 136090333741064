<button matRipple type="button" aria-label="decrement"
    class="bg-transparent outline-none border-none !p-0 !w-[34px] !h-[34px]" [disabled]="value <= minValue"
    (click)="decrement.emit(value)">
    <div class="rounded-md flex items-center justify-center h-full" [class.cursor-pointer]="value > minValue">
        <mat-icon class="!text-[16px] !h-4 !w-4" svgIcon="vhc-remove"></mat-icon>
    </div>
</button>

<div class="grow text-center px-2 text-sm text-vhNeutral-10" *ngIf="orientation === 'horizontal'; else vertical">
    {{value}}{{showMore? '+' : ''}} {{value === 1 ? text : pluralText || text}}
</div>

<ng-template #vertical>
    <div class="grow text-center px-2 text-sm text-vhNeutral-10">
        <div>
            {{value}}{{showMore? '+' : ''}}
        </div>
        <div>
            {{value === 1 ? text : pluralText || text}}
        </div>
    </div>
</ng-template>

<button matRipple class="bg-transparent outline-none cursor-pointer border-none !p-0 !w-[34px] !h-[34px]" type="button"
    aria-label="Increment" (click)="increment.emit(value)">
    <div class="rounded-md flex items-center justify-center h-full">
        <mat-icon class="!text-[16px] !h-4 !w-4" svgIcon="vhc-add"></mat-icon>
    </div>
</button>