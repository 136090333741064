import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ISuggestion } from '../models/api-models/suggestion';

@Injectable({
  providedIn: 'root'
})
export class CurrentDestinationService {
  private _currentDestination$ = new BehaviorSubject<ISuggestion | null>(null);
  public get currentDestination$(): Observable<ISuggestion | null> { return this._currentDestination$.asObservable(); }

  public setCurrentDestination(destination: ISuggestion | null) {
    this._currentDestination$.next(destination);
  }
}
