import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { MatRipple } from '@angular/material/core';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'vh-inc-dec',
  standalone: true,
  imports: [
    MatRipple,
    MatIcon,
    NgIf
  ],
  templateUrl: './inc-dec.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IncDecComponent {
  @Input()
  public text: string = '';

  @Input()
  public pluralText: string = '';

  @Input()
  public value: number = 0;

  @Input()
  public showMore = false;

  @Input()
  public orientation: 'horizontal' | 'vertical' = 'horizontal';

  @Input()
  public minValue: number = 0;

  /** Emits when increment is needed. The event will contain the current value. */
  @Output()
  public increment = new EventEmitter<number>();

  /** Emits when decrement is needed. The event will contain the current value. */
  @Output()
  public decrement = new EventEmitter<number>();

  @HostBinding('class') cssClasses = "flex items-center border border-vhGray-200 rounded-[4px] border-solid p-[3px]";
}
